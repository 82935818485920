import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/share-2.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function CrossPromotion() {
  const backgroundColor = "#6086B0";
  const subtitle = "Cross-Promotion";
  const title =
    "Share your TikTok video on other social media platforms to drive more traffic to your TikTok profile.";

  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: backgroundColor,
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>{subtitle}</Text>
                <Text variant="h1" sx={classes.title}>
                  {title}
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              In 2024, TikTok remains a powerhouse for viral content creation.
              <br /> <br />
              However, leveraging other social media platforms for
              cross-promotion can significantly enhance your visibility and
              drive more traffic to your TikTok profile.
              <br /> <br /> Here&apos;s how to effectively cross-promote your
              TikTok videos:
            </Text>

            <Text sx={classes.h2}>1. Identify Your Key Platforms</Text>
            <Text sx={classes.bodyText}>
              Start by identifying which social media platforms your target
              audience frequents besides TikTok.
              <br /> <br /> Instagram, Twitter, Facebook, and LinkedIn each have
              unique user demographics.
              <br /> <br /> Tailoring your cross-promotion strategy to platforms
              where your audience is most active can yield the best results.
            </Text>

            <Text sx={classes.h2}>2. Tailor Content for Each Platform</Text>
            <Text sx={classes.bodyText}>
              When sharing your TikTok videos on other platforms, tailor your
              approach to fit each platform&apos;s norms and user expectations.
              <br /> <br />
              For example, on Instagram, you might share a teaser of your TikTok
              video on Stories or Reels, inviting viewers to watch the full
              video on TikTok.
            </Text>

            <Text sx={classes.h2}>3. Use Platform-Specific Features</Text>
            <Text sx={classes.bodyText}>
              Many social platforms have features designed to share short-form
              video content easily.
              <br /> <br /> Utilize Instagram Reels, Twitter Fleets, and
              Facebook Stories to share your TikTok content creatively.
              <br /> <br /> These features often have high engagement rates and
              can help direct traffic back to your TikTok profile.
            </Text>

            <Text sx={classes.h2}>4. Embed Videos in Blog Posts</Text>
            <Text sx={classes.bodyText}>
              If you have a website or blog, embedding your TikTok videos in
              relevant blog posts can be an effective way to gain additional
              views.
              <br /> <br /> This not only enriches your blog content but also
              introduces your TikTok presence to your website audience.
            </Text>

            <Text sx={classes.h2}>5. Leverage Hashtags and Tags</Text>
            <Text sx={classes.bodyText}>
              Just as with TikTok, using relevant hashtags and tagging other
              users or brands (when appropriate) on platforms like Instagram and
              Twitter can increase the discoverability of your cross-posted
              content.
              <br /> <br /> This can attract viewers who follow those hashtags
              or tags back to your TikTok profile.
            </Text>

            <Text sx={classes.h2}>6. Engage with Your Community</Text>
            <Text sx={classes.bodyText}>
              Cross-promotion isn&apos;t just about posting content on other
              platforms; it&apos;s also about engaging with your community
              across these platforms.
              <br /> <br /> Respond to comments, participate in relevant
              conversations, and be active.
              <br /> <br /> This helps build a loyal following that&apos;s
              engaged with your content, regardless of the platform.
            </Text>

            <Text sx={classes.h2}>7. Monitor and Analyze Your Efforts</Text>
            <Text sx={classes.bodyText}>
              Use analytics tools available on social media platforms to track
              the effectiveness of your cross-promotion efforts.
              <br /> <br /> Monitoring which platforms drive the most traffic to
              your TikTok profile can help you refine your strategy and focus on
              the most rewarding channels.
              <br /> <br /> Cross-promotion is a powerful strategy to amplify
              your TikTok content&apos;s reach and drive more traffic to your
              profile.
              <br /> <br /> By strategically sharing your videos across
              different platforms, tailoring content to fit each platform&apos;s
              unique environment, and engaging with your audience, you can
              significantly enhance your online presence and follower engagement
              in 2024.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
