import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  navbar: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    padding: "32px 0",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    boxSizing: "border-box",
    [customTheme.breakpoints.down("md")]: {
      padding: "32px 16px",
    },
    [customTheme.breakpoints.down("md")]: {
      padding: "24px 16px",
    },
  },
  logo: {
    maxWidth: "186px",
  },
  globalLinks: {
    textDecoration: "none",
    color: "#2A2A2A",
  },
  globalLink: {
    fontSize: "16px",
    fontWeight: "bold",
  },
};
