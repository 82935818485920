import React from "react";
import { Box } from "@mui/material";
// import CardMedia from '@mui/material/CardMedia'
// import JSZip from "jszip";
import "./Home.css";

// import components
import Text from "../components/atoms/Text/Text";
import ChecklistSectionHeader from "../components/molecules/ChecklistSectionHeader/ChecklistSectionHeader";
import CheckListItem from "../components/molecules/CheckListItem/CheckListItem";

// import style
import { classes } from "./HomeStyle";

// import icons
import search from "../assets/icons/search.svg";
import user from "../assets/icons/user.svg";
import lightBulb from "../assets/icons/light-bulb.svg";
import eye from "../assets/icons/eye.svg";
import sparkles from "../assets/icons/sparkles.svg";
import mic from "../assets/icons/mic.svg";
import clock from "../assets/icons/clock.svg";
import alertCircle1 from "../assets/icons/alert-circle-1.svg";
import volume1 from "../assets/icons/volume-1.svg";
import hash from "../assets/icons/hash.svg";
import calendar1 from "../assets/icons/calendar-1.svg";
import messageCircle from "../assets/icons/message-circle.svg";
import share2 from "../assets/icons/share-2.svg";
import star from "../assets/icons/star.svg";
import pieChart from "../assets/icons/pie-chart.svg";
import refreshCV from "../assets/icons/refresh-cw.svg";
import dollarSign from "../assets/icons/dollar-sign.svg";
import heartShakeHands from "../assets/icons/heart-shake-hands.svg";
// import uploadCloudIcon from "../assets/icons/upload-cloud.svg";
// import videoFileIcon from "../assets/icons/video-file.svg";
// import xIcon from "../assets/icons/close.svg";
// import refreshIcon from "../assets/icons/refresh-cw.svg";

export default function Home() {
  // const [loading, setLoading] = useState(false);

  // const fileInputRef = useRef(null);

  return (
    <Box sx={classes.outerContainer}>
      <Box component="header">
        <Text variant="h1" sx={classes.title}>
          Viral TikTok Video Checklist
        </Text>
        <Text sx={classes.subtitle}>
          Your step-by-step guide to creating viral TikTok videos.
        </Text>
      </Box>

      <Box component="main" sx={classes.mainContent}>
        <ChecklistSectionHeader
          title="1. Pre-Production"
          backgroundColor="#FC9F1D"
        />
        <Box sx={classes.itemGrid}>
          <CheckListItem
            icon={search}
            iconBackgroundColor="#FFB44A"
            title="Trend Research"
            description={`Regularly explore TikTok's "For You" and "Discover" pages to identify current trends, including popular sounds, hashtags, and video formats.`}
            link="/tiktok-trend-research"
          />
          <CheckListItem
            icon={user}
            iconBackgroundColor="#FFCC85"
            title="Audience Understanding"
            description={`Define your target audience's interests, challenges, and preferences. Tailor your content to meet these needs.`}
            link="/tiktok-audience-understanding"
          />
          <CheckListItem
            icon={lightBulb}
            iconBackgroundColor="#FFEBCF"
            title="Content Planning"
            description={`Brainstorm content ideas that align with trending topics but also showcase your unique perspective or talent.`}
            link="/tiktok-content-planning"
          />
        </Box>

        <ChecklistSectionHeader
          title="2. Production"
          backgroundColor="#FE7B47"
        />
        <Box sx={classes.itemGrid}>
          <CheckListItem
            icon={eye}
            iconBackgroundColor="#FF9165"
            title="Engaging Start"
            description={`Craft a compelling opening to grab attention within the first 3 seconds.`}
            link="/tiktok-engaging-start"
          />
          <CheckListItem
            icon={sparkles}
            iconBackgroundColor="#FFA682"
            title="High-Quality Visuals"
            description={`Ensure your video is visually appealing with clear, high-resolution footage. Use natural lighting and stable video shots.`}
            link="/tiktok-high-quality-visuals"
          />
          <CheckListItem
            icon={mic}
            iconBackgroundColor="#FFBEA4"
            title="Clear Audio"
            description={`Use a good microphone. Clear and crisp audio is crucial, especially if your video involves speaking or music.`}
            link="/tiktok-clear-audio"
          />
          <CheckListItem
            icon={clock}
            iconBackgroundColor="#FFD9C9"
            title="Optimal Length"
            description={`Keep your video length appropriate to your content, generally between 15 to 60 seconds, to maintain viewer engagement.`}
            link="/tiktok-optimal-length"
          />
        </Box>

        <ChecklistSectionHeader
          title="3. Post-Production"
          backgroundColor="#D05B61"
        />
        <Box sx={classes.itemGrid}>
          <CheckListItem
            icon={alertCircle1}
            iconBackgroundColor="#E48187"
            title="Edit for Impact"
            description={`Use editing techniques to make your video more engaging. Include transitions, effects, and text overlays where appropriate.`}
            link="/tiktok-edit-for-impact"
          />
          <CheckListItem
            icon={volume1}
            iconBackgroundColor="#F09DA2"
            title="Add Trending Sounds"
            description={`Overlay your video with trending sounds or music to increase its discoverability.`}
            link="/tiktok-add-trending-sounds"
          />
          <CheckListItem
            icon={hash}
            iconBackgroundColor="#FABDC0"
            title="Incorporate Hashtags"
            description={`Use a mix of trending and niche hashtags relevant to your content for broader reach.`}
            link="/tiktok-incorporate-hashtags"
          />
        </Box>

        <ChecklistSectionHeader
          title="4. Publication"
          backgroundColor="#B45F9C"
        />
        <Box sx={classes.itemGrid}>
          <CheckListItem
            icon={calendar1}
            iconBackgroundColor="#C375AD"
            title="Strategic Posting"
            description={`Post at peak times when your target audience is most active. Use TikTok Analytics to find these times.`}
            link="/tiktok-strategic-posting"
          />
          <CheckListItem
            icon={messageCircle}
            iconBackgroundColor="#E1A3CF"
            title="Engage With Your Audience"
            description={`Prompt viewers to engage by asking a question or encouraging them to leave a comment. Respond to comments to boost engagement.`}
            link="/tiktok-engage-with-your-audience"
          />
        </Box>

        <ChecklistSectionHeader
          title="5. Promotion"
          backgroundColor="#406792"
        />
        <Box sx={classes.itemGrid}>
          <CheckListItem
            icon={share2}
            iconBackgroundColor="#6086B0"
            title="Cross-Promotion"
            description={`Share your TikTok video on other social media platforms to drive more traffic to your TikTok profile.`}
            link="/tiktok-cross-promotion"
          />
          <CheckListItem
            icon={star}
            iconBackgroundColor="#81A3C9"
            title="Collaborate"
            description={`Engage in duets, challenges, or collaborate with other TikTok creators to tap into new audiences.`}
            link="/tiktok-collaborate"
          />
        </Box>

        <ChecklistSectionHeader title="6. Analysis" backgroundColor="#369CBB" />
        <Box sx={classes.itemGrid}>
          <CheckListItem
            icon={pieChart}
            iconBackgroundColor="#4AADCB"
            title="Review Performance"
            description={`Use TikTok Analytics to review the performance of your videos. Note what works and adjust your strategy accordingly.`}
            link="/tiktok-review-perforamnce"
          />
        </Box>

        <ChecklistSectionHeader
          title="7. Continual Improvement"
          backgroundColor="#6AC9D6"
        />
        <Box sx={classes.itemGrid}>
          <CheckListItem
            icon={refreshCV}
            iconBackgroundColor="#85D9E4"
            title="Iterate"
            description={`Continuously refine your content based on feedback and analytics. Experiment with new ideas and trends.`}
            link="/tiktok-iterate"
          />
        </Box>

        <ChecklistSectionHeader
          title="8. Monetization Strategies"
          backgroundColor="#5DCEA5"
        />
        <Box sx={classes.itemGrid}>
          <CheckListItem
            icon={dollarSign}
            iconBackgroundColor="#78DAB7"
            title="TikTok’s Creator Fund"
            description={`Once eligible, apply to TikTok’s Creator Fund to earn money based on the engagement and views of your videos.`}
            link="/tiktok-tiktoks-creator-fund"
          />
          <CheckListItem
            icon={heartShakeHands}
            iconBackgroundColor="#8BE8C6"
            title="Brand Partnerships"
            description={`Leverage your viral success to attract brand deals and sponsored content opportunities. Ensure brand partnerships align with your audience's interests for authentic integration.`}
            link="/tiktok-brand-partnerships"
          />
        </Box>
      </Box>
    </Box>
  );
}
