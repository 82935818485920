import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, CardMedia } from "@mui/material";
import { Link } from "react-router-dom";

// import components
import Text from "../../atoms/Text/Text";
import { classes } from "./CheckListItemStyle";

// import icons
import checkWhite from "../../../assets/icons/check-white.svg";

export default function CheckListItem({
  icon,
  iconBackgroundColor,
  title,
  description,
  link,
}) {
  const [selected, setSelected] = useState(false);

  return (
    <Box sx={classes.container}>
      <Box sx={classes.flexBox}>
        <Box
          sx={{
            ...classes.iconContainer,
            backgroundColor: iconBackgroundColor,
          }}
        >
          <CardMedia component="img" image={icon} alt="" sx={classes.icon} />
        </Box>

        <Link to={link} style={{ textDecoration: "none" }}>
          <Box sx={classes.textContainer}>
            <Text sx={classes.title}>{title}</Text>
            <Text sx={classes.description}>{description}</Text>
            <Text sx={classes.more}>Read more</Text>
          </Box>
        </Link>

        <Box
          onClick={() => {
            setSelected(!selected);
          }}
          sx={{
            ...classes.checkbox,
            backgroundColor: selected ? "#2A2A2A" : "#D9D9D9",
            padding: selected ? "8px" : "20px",
            // padding: "8px",
          }}
        >
          {selected && (
            <CardMedia
              component="img"
              image={checkWhite}
              alt=""
              sx={classes.checkmark}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

// Define prop types
CheckListItem.propTypes = {
  icon: PropTypes.string.isRequired,
  iconBackgroundColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};
