import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    marginTop: "40px",
    padding: "24px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [customTheme.breakpoints.down("md")]: {
      padding: "24px 16px",
    },
  },
  footerName: {},
};
