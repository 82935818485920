import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    // marginTop: "-1px",
    display: "inline-block",
    width: "100%",
    padding: "32px 22px",
    border: "1px solid #DEDEDE",
    boxSizing: "border-box",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  // Icon
  iconContainer: {
    padding: "16px",
    width: "fit-content",
    height: "fit-content",
    borderRadius: "4px",
  },
  icon: {
    width: "32px",
    height: "32px",
  },

  // Text
  textContainer: {
    marginLeft: "16px",
    maxWidth: "290px",
    color: "#2A2A2A",
    [customTheme.breakpoints.down("sm")]: {
      maxWidth: "inherit",
    },
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  description: {
    marginTop: "8px",
    fontSize: "16px",
  },
  more: {
    marginTop: "8px",
    fontWeight: "bold",
  },

  // Checkbox
  checkbox: {
    marginLeft: "24px",
    // width: "40px",
    // height: "40px",
    borderRadius: "100px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "wrap",
    width: "fit-content",
    height: "fit-content",
    // transition: "0.2s",
    [customTheme.breakpoints.down("md")]: {
      marginLeft: "16px",
    },
  },
  checkmark: {
    width: "24px",
    height: "24px",
  },
};
