import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100vw",
    [customTheme.breakpoints.down("md")]: {
      padding: "0 16px",
      boxSizing: "border-box",
    },
  },
  innerHeader: {
    maxWidth: "730px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  backBtn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
  },
  backIcon: {
    width: "24px",
    height: "24px",
  },
  backTxt: {
    color: "#2A2A2A",
    fontWeight: "bold",
  },

  bannerIconContainer: {
    marginTop: "40px",
    // position: "relative",
    display: "flex",
    justifyContaner: "center",
    alignItems: "center",
    padding: "16px",
    borderRadius: "4px",
  },
  bannerIcon: {
    borderRadius: "24px",
    maxWidth: "600px",
    // zIndex: 1,
  },

  titleAndMetaSection: {
    textAlign: "center",
  },
  title: {
    marginTop: "24px",
    fontSize: "48px",
    color: "#2A2A2A",
    lineHeight: "120%",
    [customTheme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
  subtitle: {
    marginTop: "16px",
    fontSize: "20px",
    color: "#757575",
  },

  contentContainer: {
    maxWidth: "730px",
    display: "flex",
    flexDirection: "column",
    padding: "40px 0 0 0",
  },
  h2: {
    fontSize: "24px",
    lineHeight: 1.2,
    fontWeight: "bold",
    marginTop: "2.25rem !important",
    marginBottom: "1.75rem !important",
  },
  h3: {
    fontSize: "1.5rem",
    lineHeight: 1.2,
    marginBottom: "1.4rem",
    fontWeight: "bold",
  },
  bodyText: {
    fontSize: "1.125rem",
    marginBottom: "1.3rem",
    lineHeight: 1.8,
  },
  contentImage: {
    marginBottom: "1.3rem",
    width: "100%",
    borderRadius: "16px",
  },
  link: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#0c8654",
  },
};
