import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/mic.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function ClearAudio() {
  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: "#FFBEA4",
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>Clear Audio</Text>
                <Text variant="h1" sx={classes.title}>
                  High-Quality Audio: The Key to Captivating TikTok Content
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              In the visual world of TikTok, audio quality can often be the
              unsung hero of content creation.
              <br /> <br /> Clear and crisp audio isn&apos;t just a
              nice-to-have; it&apos;s essential, especially when your video
              involves speaking, singing, or music.
              <br /> <br /> Here&apos;s how to ensure your TikTok videos sound
              as good as they look:
            </Text>

            <Text sx={classes.h2}>1. Invest in a Good Microphone</Text>
            <Text sx={classes.bodyText}>
              The built-in microphone on your smartphone or camera might work
              for casual videos, but to truly elevate your audio quality,
              consider investing in an external microphone.
              <br /> <br /> For on-the-go recording, a lavalier mic that clips
              to your shirt can significantly improve voice clarity.
              <br /> <br /> For more stationary setups, a shotgun microphone
              directed towards you can capture sound more directly and reduce
              background noise.
            </Text>

            <Text sx={classes.h2}>2. Find Your Quiet Space</Text>
            <Text sx={classes.bodyText}>
              Background noise can distract from your message and make your
              videos feel less professional.
              <br /> <br /> When recording, find the quietest space possible.
              <br /> <br /> Indoors, rooms with carpets, curtains, and soft
              furniture can help absorb sound and reduce echo.
              <br /> <br /> If you&apos;re filming outdoors, try to choose calm
              environments and avoid recording near busy roads or in windy
              conditions.
            </Text>

            <Text sx={classes.h2}>3. Test and Adjust Your Audio Levels</Text>
            <Text sx={classes.bodyText}>
              Before you start recording your final take, do a few test
              recordings to check your audio levels.
              <br /> <br /> Make sure your voice or music isn&apos;t too quiet
              or so loud that it distorts.
              <br /> <br /> Most recording devices or editing software will have
              audio meters you can use to gauge levels.
              <br /> <br /> Aim for your audio to hit just below the &quot;red
              zone&quot; on these meters for the best quality.
            </Text>

            <Text sx={classes.h2}>4. Utilize Audio Editing Tools</Text>
            <Text sx={classes.bodyText}>
              After recording, you can use audio editing software to fine-tune
              your sound.
              <br /> <br /> Programs like Audacity (free) or Adobe Audition
              (paid) offer tools to remove background noise, equalize voice
              levels, and enhance clarity.
              <br /> <br /> Even a slight adjustment can make a significant
              difference in the final output.
            </Text>

            <Text sx={classes.h2}>5. Sync Your Sound</Text>
            <Text sx={classes.bodyText}>
              For videos where you&apos;re syncing audio post-recording, like a
              music cover or voice-over, ensure your audio and video are
              perfectly in sync.
              <br /> <br /> Misalignments can be distracting and reduce the
              overall quality of your content.
              <br /> <br /> Editing software can help you align tracks visually
              and audibly for seamless playback.
            </Text>

            <Text sx={classes.h2}>
              Bonus Tip: Leverage TikTok&apos;s Audio Library
            </Text>
            <Text sx={classes.bodyText}>
              If you&apos;re adding music or sound effects, TikTok&apos;s vast
              audio library is a goldmine of high-quality options that are
              already optimized for the platform.
              <br /> <br /> Using these tracks can not only save editing time
              but also ensure your audio matches the platform&apos;s standards.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
