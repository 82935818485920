import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/message-circle.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function EngageWithYourAudience() {
  const backgroundColor = "#E1A3CF";
  const subtitle = "Engage With Your Audience";
  const title =
    "Engaging With Your Audience on TikTok: A 2024 Strategy for Enhanced Interaction";

  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: backgroundColor,
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>{subtitle}</Text>
                <Text variant="h1" sx={classes.title}>
                  {title}
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              Engagement is the currency of social media, and TikTok is no
              exception.
              <br /> <br /> In 2024, fostering a two-way conversation with your
              audience is more crucial than ever.
              <br /> <br /> Here&apos;s how to effectively engage with your
              viewers on TikTok:
            </Text>

            <Text sx={classes.h2}>1. Prompt Engagement Through Content</Text>
            <Text sx={classes.bodyText}>
              Start by encouraging interaction directly within your videos.
              <br /> <br /> Ask a thought-provoking question, propose a
              challenge, or simply ask viewers to share their opinions in the
              comments.
              <br /> <br /> This not only boosts engagement metrics but also
              makes your audience feel valued and heard.
            </Text>

            <Text sx={classes.h2}>2. Harness the Power of Hashtags</Text>
            <Text sx={classes.bodyText}>
              Utilize hashtags to prompt specific types of engagement.
              <br /> <br /> For example, create or participate in hashtag
              challenges that encourage users to create content in response to
              yours.
              <br /> <br /> This can exponentially increase your visibility and
              engagement.
            </Text>

            <Text sx={classes.h2}>3. Respond to Comments</Text>
            <Text sx={classes.bodyText}>
              Make it a habit to respond to comments on your videos.
              <br /> <br /> Whether it&apos;s a simple thank you, answering a
              question, or engaging in a deeper conversation, responding to
              comments shows your audience that you value their input and
              encourages further interaction.
            </Text>

            <Text sx={classes.h2}>4. Use TikTok&apos;s Q&A Feature</Text>
            <Text sx={classes.bodyText}>
              If available, leverage TikTok&apos;s Q&A feature to directly
              answer questions from your followers.
              <br /> <br /> This feature not only boosts engagement but also
              allows for a more organized way to interact with your
              audience&apos;s queries and comments.
            </Text>

            <Text sx={classes.h2}>5. Engage With Other TikTok Creators</Text>
            <Text sx={classes.bodyText}>
              Interaction shouldn&apos;t be limited to your audience.
              <br /> <br /> Engage with content from other TikTok creators as
              well.
              <br /> <br /> Commenting, liking, or even collaborating with other
              creators can expand your reach and introduce your content to new
              audiences.
            </Text>

            <Text sx={classes.h2}>
              6. Monitor Trends for Engagement Opportunities
            </Text>
            <Text sx={classes.bodyText}>
              Stay abreast of trending sounds, challenges, and topics on TikTok.
              <br /> <br />
              Participating in these trends can provide a natural avenue for
              engagement, as your content becomes part of a larger,
              platform-wide conversation.
            </Text>

            <Text sx={classes.h2}>7. Personalize Your Engagement</Text>
            <Text sx={classes.bodyText}>
              When responding to comments or creating content in response to
              audience questions, personalize your engagement.
              <br /> <br /> Use the commenter&apos;s name or reference their
              comment/question directly in your videos.
              <br /> <br /> Personalization can significantly increase loyalty
              and engagement from your viewers.
              <br /> <br /> Engaging with your audience on TikTok in 2024
              requires a mix of proactive communication, responsiveness, and
              participation in the broader TikTok community.
              <br /> <br />
              By encouraging interaction through your content, responding to
              comments, and staying engaged with both your audience and trends,
              you can create a vibrant community around your content, driving
              both engagement and growth on the platform.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
