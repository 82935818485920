import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/eye.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

// import images
import img1 from "../../assets/images/engaging-start-img-1.png";
import img2 from "../../assets/images/engaging-start-img-2.png";
import img3 from "../../assets/images/engaging-start-img-3.png";
import img4 from "../../assets/images/engaging-start-img-4.png";
import img5 from "../../assets/images/engaging-start-img-5.png";
import img6 from "../../assets/images/engaging-start-img-6.png";
import img7 from "../../assets/images/engaging-start-img-7.png";
import img8 from "../../assets/images/engaging-start-img-8.png";

export default function EngagingStart() {
  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: "#FF9165",
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>Engaging Start</Text>
                <Text variant="h1" sx={classes.title}>
                  Engaging Start: Mastering the First 3 Seconds on TikTok
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              In the TikTok universe, where millions of videos vie for
              attention, the first 3 seconds of your video are crucial.
              <br /> <br /> They can make the difference between a viewer
              scrolling past or stopping to watch.
              <br /> <br />
              Here’s how to ensure your TikTok videos grab attention from the
              get-go:
            </Text>

            <Text sx={classes.h2}>1. Start with a Bang</Text>
            <Text sx={classes.bodyText}>
              Your video should start with something visually compelling or
              intriguing.
              <br /> <br />
              This could be a bold statement, a surprising fact, or a visually
              striking image.
              <br /> <br />
              Think about what will make viewers pause and want to see what
              comes next.
              <br /> <br />
              <CardMedia
                component="img"
                image={img1}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>2. Use the Power of Questions</Text>
            <Text sx={classes.bodyText}>
              Posing a question at the beginning of your video can pique
              curiosity and encourage viewers to watch until the end for the
              answer.
              <br /> <br />
              Make it relevant and thought-provoking to hook your audience
              effectively.
              <br /> <br />
              <CardMedia
                component="img"
                image={img2}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>3. Leverage Trending Sounds or Music</Text>
            <Text sx={classes.bodyText}>
              Starting your video with a trending sound or piece of music can
              instantly make it more relatable and engaging.
              <br /> <br />
              TikTok users are drawn to familiar tunes, especially those that
              are currently popular on the platform.
              <br /> <br />
              Something I&apos;ve found helpful is to search &quot;Trending
              sounds&quot;.
              <br /> <br />
              Then filter by this month so that you see the most recent videos
              talking about currently trending sounds.
              <br /> <br />
              For example, @im_mmxvii often shares currently trending sounds.
              <br /> <br />
              If you use these sounds in your videos right when they&apos;re
              getting popular, then you&apos;ll be riding the trending wave.
              <br /> <br />
              <CardMedia
                component="img"
                image={img3}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>4. Include Text Teasers</Text>
            <Text sx={classes.bodyText}>
              Use on-screen text to tease the content of your video within the
              first few seconds.
              <br /> <br />A short, punchy phrase that outlines what viewers can
              expect can entice them to stick around.
              <br /> <br />
              <CardMedia
                component="img"
                image={img4}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>5. Jump Right into Action</Text>
            <Text sx={classes.bodyText}>
              Avoid lengthy introductions.
              <br /> <br />
              Instead, jump straight into the action or the core message of your
              video.
              <br /> <br />
              TikTok users have short attention spans, and immediate engagement
              is key to retaining their interest.
              <br /> <br />
              For example, this video both explains what the video is about
              while going straight into the funny content.
              <br /> <br />
              <CardMedia
                component="img"
                image={img5}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>6. Make a Bold Statement</Text>
            <Text sx={classes.bodyText}>
              If your video revolves around a particular opinion, insight, or
              fact, stating it boldly at the beginning can grab attention.
              <br /> <br />
              Be concise and direct to make your point heard within the first
              few seconds.
              <br /> <br />
              <CardMedia
                component="img"
                image={img6}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>7. Utilize Visual Effects</Text>
            <Text sx={classes.bodyText}>
              Applying a visually appealing effect right at the start can make
              your video stand out.
              <br /> <br />
              Whether it&apos;s a filter, a transition, or an animation,
              creative visuals can captivate your audience from the very
              beginning.
              <br /> <br />
              In this video, @briannaguidryy uses visual effects to emphasize
              her points on her recent breakup, but she also interacts with the
              effects to make the video funny and relatable.
              <br /> <br />
              <CardMedia
                component="img"
                image={img7}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>8. Show Your Personality</Text>
            <Text sx={classes.bodyText}>
              Let your unique personality shine through right from the start.
              <br /> <br />
              For example, a charismatic introduction, even if brief, can make
              viewers more interested in you and what you have to say.
              <br /> <br />
              That means being confident, maybe moving your hands while you
              talk, looking into and away from the camera periodically...there
              are many ways to be charismatic.
              <br /> <br />
              However, this isn&apos;t a charisma website. There are plenty of
              videos out there on how to be charismatic.
              <br /> <br />
              I personally find that Dr. Huberman has strong charisma, which is
              one reason why he&apos;s a top podcaster.
              <br /> <br />
              <CardMedia
                component="img"
                image={img8}
                alt=""
                sx={classes.contentImage}
              />
              But you should find what works for your brand, even if that means
              showing your awkward side.
              <br /> <br />
              Play into your traits instead of avoiding them.
            </Text>

            <Text sx={classes.h2}>Engaging Start Examples in Practice:</Text>
            <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Bold Statement: A video that begins with &quot;This is why
                  you&apos;re folding your clothes all wrong!&quot; immediately
                  challenges the viewer&apos;s curiosity.
                </li>
                <li>
                  Question: Opening with &quot;Ever wondered why cats
                  purr?&quot; sets up an intriguing premise that cat lovers
                  won&apos;t be able to resist.
                </li>
                <li>
                  Trending Sound: Starting a dance video with the first beat of
                  a viral song can instantly hook viewers who recognize and love
                  the track.
                </li>
              </ul>
            </Text>
            <Text sx={classes.bodyText}>
              Crafting an engaging start is an art that requires creativity,
              understanding of your audience, and a bit of experimentation.
              <br /> <br />
              By employing these strategies, your TikTok videos will not only
              capture attention but also encourage longer viewing times and
              higher engagement rates.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
