import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    marginTop: "-2px",
    width: "100%",
    padding: "16px 0",
    textAlign: "center",
    position: "relative",
    zIndex: 1,
    [customTheme.breakpoints.down("md")]: {},
  },
  title: {
    fontSize: "18px",
    color: "#FFF",
    fontWeight: "bold",
  },
};
