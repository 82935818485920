import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/volume-1.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function AddTrendingSounds() {
  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: "#F09DA2",
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>Add Trending Sounds</Text>
                <Text variant="h1" sx={classes.title}>
                  Leveraging Trending Sounds on TikTok: A 2024 Guide to Boosting
                  Discoverability
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              In the dynamic arena of TikTok, audio isn&apos;t just a background
              element—it&apos;s a pivotal component of content strategy.
              <br /> <br /> As we navigate through 2024, integrating trending
              sounds or music into your videos has emerged as a non-negotiable
              tactic for amplifying discoverability and engagement. Here&apos;s
              how to master the art of audio on TikTok:
            </Text>

            <Text sx={classes.h2}>1. Stay Atop Trending Sounds</Text>
            <Text sx={classes.bodyText}>
              The TikTok landscape is ever-evolving, with new trends emerging
              daily.
              <br /> <br /> Regularly browse the platform&apos;s &quot;For
              You&quot; page and explore the audio suggestions to identify
              what&apos;s currently capturing the audience&apos;s imagination.
              <br /> <br /> Incorporating these sounds can significantly
              increase your content&apos;s chances of going viral.
            </Text>

            <Text sx={classes.h2}>2. Sync Your Content with Audio</Text>
            <Text sx={classes.bodyText}>
              It&apos;s not just about choosing a trending sound; it&apos;s
              about creatively integrating it into your content.
              <br /> <br /> Whether it&apos;s a dance, a lip-sync, or a thematic
              video, ensure your visuals are in harmony with the audio.
              <br /> <br /> This synchronization between sound and sight can
              captivate viewers, making your content more memorable.
            </Text>

            <Text sx={classes.h2}>3. Use Audio to Tell a Story</Text>
            <Text sx={classes.bodyText}>
              Even a short TikTok video can tell a compelling story with the
              right sound.
              <br /> <br /> Use audio to set the tone, convey emotions, or add a
              punchline to your narrative.
              <br /> <br /> Trending sounds often come with their own set of
              associated emotions or themes, which can be leveraged to enhance
              your storytelling.
            </Text>

            <Text sx={classes.h2}>4. Remix and Innovate</Text>
            <Text sx={classes.bodyText}>
              While using trending sounds, don&apos;t shy away from adding your
              unique twist.
              <br /> <br /> This could be a remix, a duet, or even using the
              sound in an unexpected context.
              <br /> <br /> Innovation within familiar auditory landscapes can
              set your content apart, making it fresh and engaging.
            </Text>

            <Text sx={classes.h2}>
              5. Balance Trending Sounds with Original Audio
            </Text>
            <Text sx={classes.bodyText}>
              While trending sounds are a powerful tool for discoverability,
              blending them with original audio can enrich your content&apos;s
              authenticity.
              <br /> <br /> Personal anecdotes, commentary, or even original
              music—if you&apos;re musically inclined—can add depth to your
              TikTok presence, making your profile a mix of trendy and genuine
              content.
            </Text>

            <Text sx={classes.h2}>6. Monitor Audio Performance</Text>
            <Text sx={classes.bodyText}>
              Use TikTok&apos;s analytics to monitor how your choices of sounds
              are performing.
              <br /> <br /> Understanding which audio tracks resonate with your
              audience can guide your future content strategy, allowing you to
              tailor your audio selections more effectively.
              <br /> <br /> Trending sounds are a gateway to TikTok success in
              2024, acting as a catalyst for visibility and engagement.
              <br /> <br /> By staying updated with audio trends, syncing sound
              with content creatively, and balancing trending sounds with
              original audio, creators can significantly enhance their TikTok
              strategy, ensuring their content not only trends but also leaves a
              lasting impact.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
