import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/calendar-1.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function StrategicPosting() {
  const backgroundColor = "#C375AD";
  const subtitle = "Strategic Posting";
  const title = "Strategic Posting on TikTok: Navigating Peak Times in 2024";

  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: backgroundColor,
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>{subtitle}</Text>
                <Text variant="h1" sx={classes.title}>
                  {title}
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              In the dynamic ecosystem of TikTok, timing is everything.
              <br /> <br />
              Strategic posting when your target audience is most active can
              significantly amplify your content&apos;s reach and engagement.
              <br /> <br /> As we step into 2024, leveraging TikTok Analytics to
              pinpoint these optimal posting times has become more crucial than
              ever. Here&apos;s how to refine your posting strategy for maximum
              impact:
            </Text>

            <Text sx={classes.h2}>1. Dive Into TikTok Analytics</Text>
            <Text sx={classes.bodyText}>
              Start by accessing your TikTok Analytics; this feature provides a
              wealth of information about your audience&apos;s behavior,
              including when they&apos;re most active.
              <br /> <br /> Look for patterns over weeks to identify peak
              activity times.
              <br /> <br /> Adjusting your posting schedule to these insights
              ensures your content has the best chance of being seen.
            </Text>

            <Text sx={classes.h2}>2. Consider Time Zones</Text>
            <Text sx={classes.bodyText}>
              If your audience spans across different time zones, consider the
              majority&apos;s location or segment your content posting times to
              cater to various regions.
              <br /> <br /> This approach helps in maximizing engagement across
              all your audience demographics.
            </Text>

            <Text sx={classes.h2}>3. Weekdays vs. Weekends</Text>
            <Text sx={classes.bodyText}>
              Patterns can vary between weekdays and weekends.
              <br /> <br /> Generally, evenings and late nights are prime times
              as people unwind.
              <br /> <br />
              However, weekends might see a shift, with midday posts performing
              better due to changes in routine.
              <br /> <br /> Keep an eye on these trends and adjust accordingly.
            </Text>

            <Text sx={classes.h2}>4. Experiment and Analyze</Text>
            <Text sx={classes.bodyText}>
              The &apos;best time to post&apos; is not one-size-fits-all; it
              varies by niche, audience, and even the type of content.
              <br /> <br />
              Experiment with posting at different times and days to gather your
              own data.
              <br /> <br /> Use TikTok Analytics to measure the success of these
              experiments and refine your strategy based on concrete results.
            </Text>

            <Text sx={classes.h2}>5. Align with Trending Times</Text>
            <Text sx={classes.bodyText}>
              Pay attention to when trending challenges and sounds are most
              active.
              <br /> <br /> Posting content that aligns with these trends during
              their peak can significantly increase your visibility and
              participation in viral movements.
            </Text>

            <Text sx={classes.h2}>6. Stay Updated with Platform Changes</Text>
            <Text sx={classes.bodyText}>
              TikTok&apos;s algorithm and user behavior are in constant flux.
              <br /> <br />
              What works today might not work tomorrow.
              <br /> <br /> Stay informed about the platform&apos;s updates and
              be ready to adapt your posting strategy to maintain optimal
              engagement.
            </Text>

            <Text sx={classes.h2}>7. Leverage Scheduling Tools</Text>
            <Text sx={classes.bodyText}>
              To maintain consistency without being glued to your phone,
              consider using TikTok&apos;s scheduling feature or third-party
              tools.
              <br /> <br /> This way, you can plan your content calendar in
              advance, ensuring you hit those peak times even when you&apos;re
              busy.
              <br /> <br />
              Strategic posting on TikTok in 2024 demands a blend of
              analytics-driven insights and adaptability.
              <br /> <br /> By understanding your audience&apos;s active hours
              and aligning your content with these windows, you can
              significantly enhance your TikTok presence, driving greater
              engagement and reach.
              <br /> <br /> Remember, success on TikTok is not just about what
              you post, but when you post it.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
