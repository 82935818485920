import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/refresh-cw.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function Iterate() {
  const backgroundColor = "#85D9E4";
  const subtitle = "Iterate";
  const title =
    "Continuously Refine Your TikTok Content Strategy: A 2024 Guide";

  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: backgroundColor,
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>{subtitle}</Text>
                <Text variant="h1" sx={classes.title}>
                  {title}
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              In the dynamic world of TikTok, staying ahead means being in a
              constant state of iteration.
              <br /> <br /> Leveraging feedback and analytics is not just
              recommended; it&apos;s essential for growth.
              <br /> <br /> Here&apos;s how you can continuously refine your
              content strategy on TikTok:
            </Text>

            <Text sx={classes.h2}>1. Dive Deep into TikTok Analytics</Text>
            <Text sx={classes.bodyText}>
              Start with TikTok&apos;s built-in analytics to understand the
              performance of your videos.
              <br /> <br /> Look for patterns in videos that perform well in
              terms of views, engagement, and watch time.
              <br /> <br />
              Identify the characteristics that these videos share, whether it’s
              the type of content, the posting time, or the use of specific
              hashtags and sounds.
            </Text>

            <Text sx={classes.h2}>2. Listen to Your Audience</Text>
            <Text sx={classes.bodyText}>
              Feedback doesn&apos;t always come in numbers.
              <br /> <br /> Pay close attention to the comments on your videos.
              <br /> <br /> Your audience might request more of a particular
              type of content or suggest improvements.
              <br /> <br /> This direct feedback is invaluable for tailoring
              your content to audience preferences.
            </Text>

            <Text sx={classes.h2}>3. Experiment with New Content Ideas</Text>
            <Text sx={classes.bodyText}>
              Innovation is key on TikTok.
              <br /> <br /> Don&apos;t shy away from experimenting with new
              content ideas, formats, or trends.
              <br /> <br /> Use the insights gained from your analytics and
              audience feedback to try out content that could potentially
              resonate with your followers or attract a new audience.
            </Text>

            <Text sx={classes.h2}>4. Adjust Posting Times</Text>
            <Text sx={classes.bodyText}>
              Analytics can reveal when your audience is most active.
              <br /> <br /> Experiment with posting times and measure the impact
              on your video&apos;s performance.
              <br /> <br /> Adjusting your posting schedule to align with your
              audience&apos;s peak activity times can significantly increase
              your content&apos;s visibility.
            </Text>

            <Text sx={classes.h2}>5. Embrace Trends and Challenges</Text>
            <Text sx={classes.bodyText}>
              TikTok thrives on trends and challenges.
              <br /> <br /> Use your analytics to identify which trends or
              challenges your audience engages with most.
              <br /> <br /> Incorporate these into your content strategy, but
              always try to add a unique twist that reflects your personality or
              brand.
            </Text>

            <Text sx={classes.h2}>6. Refine Your Hashtag Strategy</Text>
            <Text sx={classes.bodyText}>
              Hashtags play a crucial role in discoverability on TikTok.
              <br /> <br /> Analyze which hashtags have brought the most
              visibility to your videos and refine your hashtag strategy
              accordingly.
              <br /> <br /> Don&apos;t hesitate to mix popular hashtags with
              niche-specific ones to balance reach and relevance.
            </Text>

            <Text sx={classes.h2}>7. Leverage Collaborations</Text>
            <Text sx={classes.bodyText}>
              Collaborations with other creators can open up your content to new
              audiences.
              <br /> <br /> Use analytics to identify potential collaborators
              who share a similar audience or content niche.
              <br /> <br /> Collaborative content can not only boost your reach
              but also bring fresh perspectives to your channel.
            </Text>

            <Text sx={classes.h2}>8. Optimize Video Length</Text>
            <Text sx={classes.bodyText}>
              While TikTok allows videos up to 3 minutes, analytics can help you
              determine the ideal length for your content.
              <br /> <br /> Shorter videos might work better for quick, engaging
              content, while longer videos can be reserved for tutorials,
              stories, or more detailed explorations of a topic.
              <br /> <br /> The key to sustained growth and engagement on TikTok
              lies in a cycle of continuous improvement.
              <br /> <br /> By closely monitoring your TikTok analytics,
              listening to your audience, and being willing to experiment and
              adjust your strategy, you can ensure that your content remains
              fresh, relevant, and engaging.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
