import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/sparkles.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function HighQualityVisuals() {
  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: "#FFA682",
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>High-Quality Visuals</Text>
                <Text variant="h1" sx={classes.title}>
                  High-Quality Visuals: Elevating Your TikTok Presence
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              In the realm of TikTok, where content is king, the quality of your
              visuals plays a pivotal role in determining the success and reach
              of your videos.
              <br /> <br /> High-resolution footage, natural lighting, and
              stable video shots are not just elements of visual appeal; they
              are the cornerstones of engaging and share-worthy content.
              Here&apos;s how to ensure your TikTok videos stand out with
              superior visual quality:
            </Text>

            <Text sx={classes.h2}>1. Prioritize High-Resolution Footage</Text>
            <Text sx={classes.bodyText}>
              Ensure your videos are shot in the highest resolution possible.
              <br /> <br />
              This doesn&apos;t necessarily require professional equipment; many
              smartphones today are capable of recording in 4K.
              <br /> <br /> High-resolution videos not only look more
              professional but also retain their quality when viewed on
              different devices, making your content more appealing to a wider
              audience.
            </Text>

            <Text sx={classes.h2}>
              2. Harness the Power of Natural Lighting
            </Text>
            <Text sx={classes.bodyText}>
              Natural light can dramatically enhance the visual quality of your
              videos without the need for expensive lighting equipment.
              <br /> <br /> Aim to shoot during the &quote;golden hours&quote; —
              shortly after sunrise or before sunset — when the light is soft,
              diffused, and flattering.
              <br /> <br />
              Properly lit videos will catch the eye of TikTok users, making
              them more likely to stop scrolling and watch.
            </Text>

            <Text sx={classes.h2}>3. Ensure Stability in Your Shots</Text>
            <Text sx={classes.bodyText}>
              A shaky video can be distracting and detract from the
              viewer&apos;s experience.
              <br /> <br /> Use a tripod or a stabilizer to keep your shots
              steady, especially if you&apos;re moving while filming.
              <br /> <br />
              For static shots, even propping your phone against a stable
              surface can make a significant difference in the video&apos;s
              quality.
            </Text>

            <Text sx={classes.h2}>4. Composition and Framing</Text>
            <Text sx={classes.bodyText}>
              Pay attention to the composition and framing of your shots.
              <br /> <br /> Rule of thirds, leading lines, and framing
              techniques can add a professional touch to your videos.
              <br /> <br /> Experiment with different angles and perspectives to
              find what best showcases your subject while keeping the video
              visually interesting.
            </Text>

            <Text sx={classes.h2}>5. Edit for Impact</Text>
            <Text sx={classes.bodyText}>
              Use editing software to enhance your videos further.
              <br /> <br /> Adjusting the brightness, contrast, and saturation
              can help your footage pop.
              <br /> <br />
              Consider adding filters or effects sparingly to maintain the
              authenticity of your video while elevating its aesthetic appeal.
            </Text>

            <Text sx={classes.h2}>Bonus Tip: Consistency in Visual Style</Text>
            <Text sx={classes.bodyText}>
              Maintaining a consistent visual style across your videos can help
              in building a recognizable brand on TikTok.
              <br /> <br /> Whether it&apos;s a color scheme, a filter, or a
              shooting style, consistency makes your content instantly
              recognizable to your followers, fostering a stronger connection.
              <br /> <br /> By focusing on high-quality visuals, you not only
              enhance the viewer&apos;s experience but also increase the
              likelihood of your videos being shared, both on TikTok and across
              other platforms.
              <br /> <br /> This not only boosts your visibility on TikTok but
              can also improve the SEO performance of any blog content related
              to your videos, as engaging, high-quality content is more likely
              to attract backlinks and social shares, key factors in
              Google&apos;s ranking algorithms.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
