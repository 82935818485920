import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/user.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

// import images
import img1 from "../../assets/images/audience-understanding-img-1.png";
import img2 from "../../assets/images/audience-understanding-img-2.png";
import img3 from "../../assets/images/audience-understanding-img-3.png";
import img4 from "../../assets/images/audience-understanding-img-4.png";
import img5 from "../../assets/images/audience-understanding-img-5.png";

export default function AudienceUnderstanding() {
  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: "#FFCC85",
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>Audience Understanding</Text>
                <Text variant="h1" sx={classes.title}>
                  Unlocking Audience Insights for TikTok Mastery
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              Audience understanding is the cornerstone of any successful TikTok
              strategy.
              <br /> <br />
              It&apos;s about delving into the hearts and minds of your viewers
              to tailor content that resonates and engages.
            </Text>

            <Text sx={classes.h2}>1. Demographic Deep Dive</Text>
            <Text sx={classes.bodyText}>
              Start by profiling your audience&apos;s demographics: age,
              location, and interests.
              <br /> <br />
              TikTok analytics provide a wealth of information, but don&apos;t
              stop there.
              <br /> <br />
              Explore user profiles, read comments, and watch reaction videos to
              grasp the nuances of your audience&apos;s preferences.
              <br /> <br />
              For instance, if you&apos;re targeting Gen Z in the US, trends
              like #AltTikTok reveal a subculture fascinated by alternative
              music and aesthetic, presenting an opportunity to tap into this
              space with relevant content.
              <br /> <br />
              <CardMedia
                component="img"
                image={img1}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>2. Psychographic Profiling</Text>
            <Text sx={classes.bodyText}>
              Beyond demographics, understand the psychographics: the attitudes,
              aspirations, and psychological criteria that drive your audience.
              <br /> <br />
              Are they seeking humor, knowledge, or a sense of community?
              <br /> <br />
              A trend like #StudyTok, where users share study tips and
              motivation, caters to an audience seeking both knowledge and
              support.
              <br /> <br />
              <CardMedia
                component="img"
                image={img2}
                alt=""
                sx={classes.contentImage}
              />
              Engaging with such content can help position you as a valuable
              contributor to this community.
            </Text>

            <Text sx={classes.h2}>3. Content Consumption Patterns</Text>
            <Text sx={classes.bodyText}>
              Analyze not just what your audience watches, but how they watch.
              <br /> <br />
              Do they prefer quick, punchy humor or longer, narrative-driven
              content? With TikTok&apos;s algorithm favoring watch time,
              understanding these patterns is crucial.
              <br /> <br /> For example, #MiniVlog shows a preference for
              day-in-the-life snippets that tell a story within TikTok&apos;s
              time constraints.
              <br /> <br />
              <CardMedia
                component="img"
                image={img3}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>4. Interactive Engagement</Text>
            <Text sx={classes.bodyText}>
              Your audience&apos;s interaction with content can guide your
              creative direction.
              <br /> <br /> Are they active in the comments, do they enjoy
              participating in challenges, or are they passive consumers?
              <br /> <br />
              In the wave of #LearnOnTikTok, educational content creators have
              found that interactive, quiz-like formats drive more engagement
              than straightforward lectures.
              <br /> <br />
              <CardMedia
                component="img"
                image={img4}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>5. Tailored Content Creation</Text>
            <Text sx={classes.bodyText}>
              With insights in hand, craft content that speaks directly to your
              audience&apos;s desires.
              <br /> <br /> If trend analysis points to a surge in
              #FitnessTransformation stories among your audience, consider
              sharing your journey or tips, infusing authenticity with relatable
              storytelling.
              <br /> <br />
              <CardMedia
                component="img"
                image={img5}
                alt=""
                sx={classes.contentImage}
              />
              Always align your content with audience interests while staying
              true to your brand.
            </Text>

            <Text sx={classes.h2}>Bonus Tip: Evolving with Your Audience</Text>
            <Text sx={classes.bodyText}>
              Remember, audience preferences evolve, and so should your content
              strategy.
              <br /> <br /> Regularly revisit your analytics, stay alert to
              shifts in the conversation, and adapt accordingly.
              <br /> <br /> Engage directly with your audience through comments
              and live sessions to get real-time feedback and foster a community
              that feels heard and valued.
              <br /> <br /> This dynamic approach ensures your content remains
              relevant and engaging, increasing your chances of viral success on
              TikTok.
              <br /> <br /> With a deep understanding of your audience, you can
              navigate the vast ocean of TikTok with content that&apos;s not
              just seen but felt, remembered, and shared.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
