import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/light-bulb.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

// import images
import img1 from "../../assets/images/content-planning-img-1.png";
import img2 from "../../assets/images/content-planning-img-2.png";
import img3 from "../../assets/images/content-planning-img-3.png";
import img4 from "../../assets/images/content-planning-img-4.png";

export default function ContentPlanning() {
  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: "#FFEBCF",
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>Content Planning</Text>
                <Text variant="h1" sx={classes.title}>
                  Crafting Trend-Inspired TikTok Content with a Personal Spin
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              Content planning for TikTok is both an art and a science.
              <br /> <br />
              It requires a mix of strategic thinking, creativity, and an
              authentic touch that can distinguish your videos in a sea of
              trending content.
            </Text>

            <Text sx={classes.h2}>
              Aim for <span style={{ textDecoration: "underline" }}>OVER</span>{" "}
              100% Retention 🎯
            </Text>
            <Text sx={classes.bodyText}>
              If you&apos;re planning on getting millions of views on your
              video, you want the goal of over 100% retention.
              <br /> <br />
              How is over 100% retention even possible?
              <br /> <br />
              That means your viewers are watching your video more than once.
              <br /> <br />
              For example, if your video is 10 seconds long and your Average
              Watch Time is 15 seconds, than your retention rate is 150%.
              <br /> <br />
              Here&apos;s the formula:
              <br /> <br />
              <span style={{ fontWeight: "bold" }}>
                Retention Rate = ( Average Watch Time / Total Video Length ) ×
                100
              </span>
              <br /> <br />
              <span style={{ fontWeight: "bold" }}>
                Average Watch Duration:
              </span>{" "}
              The average amount of time that viewers spend watching the video.
              This is calculated by dividing the total watch time by the number
              of views.
              <br /> <br />
              <span style={{ fontWeight: "bold" }}>
                Total Video Length:
              </span>{" "}
              The full duration of the video from start to finish.
              <br /> <br />
              This is something I wish someone taught me when I first started.
              <br /> <br />
              Repeat &quot;Retention rate is the most important metric&quot; in
              your head a few times because you&apos;re going to get distracted
              by views, likes, saves, comments, shares, and all the other
              metrics.
              <br /> <br />
              Now that you have that in mind, let&apos;s cover some content
              ideas.
            </Text>

            <Text sx={classes.h2}>1. Surfing the Trend Waves</Text>
            <Text sx={classes.bodyText}>
              Start by scanning the TikTok landscape for the prevailing winds –
              the trending topics and hashtags that are currently capturing the
              community&apos;s attention.
              <br /> <br /> But instead of merely riding the wave, think about
              how you can redirect it.
              <br /> <br /> For example, when the #InMyFeelings challenge was at
              its peak, creators didn&apos;t just do the dance; they added
              unexpected settings, costumes, props, and storylines to make their
              content stand out.
              <br /> <br />
              <CardMedia
                component="img"
                image={img1}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>2. Infuse Your Uniqueness</Text>
            <Text sx={classes.bodyText}>
              When brainstorming content ideas, ask yourself how you can infuse
              your unique talents and perspective.
              <br /> <br /> Are you a culinary wizard?
              <br /> <br /> Take a trending dance challenge and mix it with a
              recipe tutorial, like creating a dish to the beat.
              <br /> <br /> This fusion of trends with your expertise can create
              a refreshing twist that resonates with viewers.
              <br /> <br />
              <CardMedia
                component="img"
                image={img2}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>3. Leverage Your Expertise</Text>
            <Text sx={classes.bodyText}>
              What unique skills or knowledge can you share that aligns with a
              trending topic?
              <br /> <br /> If you’re an expert in a niche area, use trending
              hashtags to guide viewers to your educational content.
              <br /> <br /> For example, if #EcoFriendly is trending, and you
              have expertise in sustainability, create content that provides
              value and rides the trend’s popularity.
              <br /> <br />
              <CardMedia
                component="img"
                image={img3}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>4. Seasonal and Event-Driven Content</Text>
            <Text sx={classes.bodyText}>
              Plan content around seasonal events, holidays, or current affairs
              that naturally align with your brand or personality.
              <br /> <br /> For instance, during Pride Month, a fashion
              influencer could blend the rainbow aesthetics into their content,
              showing support while maintaining their fashion-focused brand.
              <br /> <br />
              <CardMedia
                component="img"
                image={img4}
                alt=""
                sx={classes.contentImage}
              />
            </Text>

            <Text sx={classes.h2}>Bonus Strategy: The Remix Technique</Text>
            <Text sx={classes.bodyText}>
              Don&apos;t be afraid to remix a trend that has already peaked.
              <br /> <br />
              Sometimes, a fresh take on a past trend can spark a new wave of
              interest.
              <br /> <br /> Analyze what made the trend popular in the first
              place and bring a new angle or revival to it.
              <br /> <br /> This approach can appeal to nostalgia while
              showcasing your creativity.
              <br /> <br /> Content planning for TikTok isn&apos;t about
              following the pack.
              <br /> <br />
              It’s about taking the pulse of what&apos;s popular and then
              creating content that’s undeniably you.
              <br /> <br /> By aligning with trends while showcasing your unique
              flair, you can craft a TikTok presence that’s both relevant and
              distinctive.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
