import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { customTheme } from "../CustomTheme/CustomTheme";

const propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  sx: PropTypes.object,
};

const defaultProps = {
  children: null,
  variant: null,
  sx: {},
};

function CraftleText({ children, variant, sx }) {
  const classes = {
    commonHeaderStyles: {
      fontWeight: "bold",
      lineHeight: 1.2,
      color: "#2a2a2a",
      fontFamily: "Inter",
    },
  };

  function TextWithVariant({ children, variant, sx }) {
    if (variant === "h1") {
      return (
        <Typography variant="h1" sx={[classes.commonHeaderStyles, sx]}>
          {children}
        </Typography>
      );
    } else if (variant === "h2") {
      return (
        <Typography variant="h2" sx={[classes.commonHeaderStyles, sx]}>
          {children}
        </Typography>
      );
    } else if (variant === "h3") {
      return (
        <Typography variant="h3" sx={[classes.commonHeaderStyles, sx]}>
          {children}
        </Typography>
      );
    } else if (variant === "h4") {
      return (
        <Typography variant="h4" sx={[classes.commonHeaderStyles, sx]}>
          {children}
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="body1"
          sx={
            ({ lineHeight: 1.5, color: "#2A2A2A", fontFamily: "Georgia" }, sx)
          }
        >
          {children}
        </Typography>
      );
    }
  }

  return (
    <ThemeProvider theme={customTheme}>
      <TextWithVariant variant={variant} sx={sx}>
        {children}
      </TextWithVariant>
    </ThemeProvider>
  );
}

CraftleText.propTypes = propTypes;
CraftleText.defaultProps = defaultProps;

export default CraftleText;
