import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

// import components
// import Text from "../../atoms/Text/Text";

// import style
import { classes } from "./NavbarStyle";

// import icons
import logo from "../../../assets/logo/viral-genius-ai-logo.svg";

export default function Navbar() {
  return (
    <Box sx={classes.navbar}>
      <a href="/">
        <CardMedia
          component="img"
          image={logo}
          alt="MP4 to GIF Logo"
          sx={classes.logo}
        />
      </a>
      {/* <nav>
        <a href="/mp4-to-gif-blog" style={classes.globalLinks}>
          <Text sx={classes.globalLink}>Blog</Text>
        </a>
      </nav> */}
    </Box>
  );
}
