import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

// import components
import Text from "../../atoms/Text/Text";
import { classes } from "./ChecklistSectionHeaderStyle";

export default function ChecklistSectionHeader({ title, backgroundColor }) {
  return (
    <Box sx={{ ...classes.container, backgroundColor }}>
      <Text variant="h2" sx={classes.title}>
        {title}
      </Text>
    </Box>
  );
}

// Define prop types
ChecklistSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};
