import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/dollar-sign.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function TikToksCreatorFund() {
  const backgroundColor = "#78DAB7";
  const subtitle = "TikTok’s Creator Fund";
  const title =
    "Monetizing Your TikTok Presence: Navigating TikTok’s Creator Fund in 2024";

  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: backgroundColor,
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>{subtitle}</Text>
                <Text variant="h1" sx={classes.title}>
                  {title}
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              TikTok&apos;s Creator Fund has emerged as a compelling way for
              creators to earn money from their content based on engagement and
              views.
              <br /> <br /> Here&apos;s a comprehensive guide for eligible
              creators looking to benefit from this opportunity in 2024:
            </Text>

            <Text sx={classes.h2}>Understanding TikTok’s Creator Fund</Text>
            <Text sx={classes.bodyText}>
              The Creator Fund is a TikTok initiative designed to reward
              creators for their creativity and engagement.
              <br /> <br /> The fund pays creators based on various factors,
              including video views, engagement rates, and adherence to
              community guidelines and terms of service.
            </Text>

            <Text sx={classes.h2}>Eligibility Criteria</Text>
            <Text sx={classes.bodyText}>
              To apply for the Creator Fund, you must:
            </Text>
            <Text sx={classes.bodyText}>
              <ul>
                <li>Be at least 18 years old.</li>
                <li>Meet a minimum threshold of 10,000 followers.</li>
                <li>
                  Have amassed at least 100,000 video views in the last 30 days.
                </li>
                <li>
                  Reside in a country where the Creator Fund is available.
                </li>
                <li>
                  Adhere to TikTok&apos;s Community Guidelines and Terms of
                  Service.
                </li>
              </ul>
            </Text>

            <Text sx={classes.h2}>Application Process</Text>
            <Text sx={classes.bodyText}>
              Once you meet the eligibility criteria:
            </Text>
            <Text sx={classes.bodyText}>
              <ol>
                <li>
                  Go to your TikTok profile and access the &quot;Settings and
                  Privacy&quot; menu.
                </li>
                <li>
                  Tap on &quot;Creator tools&quot; and select &quot;TikTok
                  Creator Fund.&quot;
                </li>
                <li>
                  Follow the prompts to apply, agreeing to the terms and
                  conditions of the fund.
                </li>
              </ol>
            </Text>

            <Text sx={classes.h2}>Maximizing Your Earnings</Text>
            <Text sx={classes.bodyText}>
              To make the most out of the Creator Fund, focus on creating
              high-quality, engaging content that resonates with your audience.
              <br /> <br />
              Pay close attention to trends, utilize relevant hashtags, and
              engage with your followers to increase your views and engagement
              rates.
            </Text>

            <Text sx={classes.h2}>Monitoring Performance</Text>
            <Text sx={classes.bodyText}>
              Use TikTok Analytics to track the performance of your videos and
              understand what content performs best.
              <br /> <br /> This insight allows you to adjust your content
              strategy to optimize for views and engagement, directly impacting
              your earnings from the Creator Fund.
            </Text>

            <Text sx={classes.h2}>Adapting to Changes</Text>
            <Text sx={classes.bodyText}>
              The digital landscape and TikTok’s policies evolve, so stay
              informed about any changes to the Creator Fund’s terms or
              eligibility criteria.
              <br /> <br /> Adapting to these changes can help you remain
              eligible and maximize your earnings over time.
              <br /> <br />
              TikTok&apos;s Creator Fund offers a tangible way to monetize your
              creativity and engagement on the platform.
              <br /> <br /> By understanding and meeting the eligibility
              criteria, focusing on content quality, and using analytics to
              refine your strategy, you can enhance your TikTok presence and
              earn money through the Creator Fund in 2024.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
