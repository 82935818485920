import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/search.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

// import images
import img1 from "../../assets/images/trend-research-img-1.png";
import img2 from "../../assets/images/trend-research-img-2.png";
import img3 from "../../assets/images/trend-research-img-3.png";
import img4 from "../../assets/images/trend-research-img-4.png";
import img5 from "../../assets/images/trend-research-img-5.png";
import img6 from "../../assets/images/trend-research-img-6.png";

export default function TrendingResearch() {
  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: "#FFC370",
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>Trend Research</Text>
                <Text variant="h1" sx={classes.title}>
                  Trend Research on TikTok: The Creator&apos;s Guide to Going
                  Viral
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              Trend research is a crucial tool for achieving viral success on
              TikTok, guiding you through the complexities of content creation.
            </Text>

            <Text sx={classes.h2}>1. Know Your Niche FIRST</Text>
            <Text sx={classes.bodyText}>
              If the purpose of your videos is to go viral, you need to
              establish your niche before doing any trend research.
              <br /> <br />
              Some popular niches include:
            </Text>
            <Text sx={classes.bodyText}>
              <ul>
                <li>Singing & Dancing</li>
                <li>Comedy</li>
                <li>Sports</li>
                <li>Anime and Comics</li>
                <li>Relationship</li>
                <li>Shows</li>
                <li>Lipsync</li>
                <li>Daily Life</li>
              </ul>
            </Text>
            <Text sx={classes.bodyText}>
              You can find more niches on TikTok&apos;s{" "}
              <a
                href="https://www.tiktok.com/explore"
                target="_blank"
                rel="noreferrer"
              >
                For You
              </a>{" "}
              page.
            </Text>
            <CardMedia
              component="img"
              image={img2}
              alt=""
              sx={classes.contentImage}
            />
            <Text sx={classes.bodyText}>
              By establishing your niche early on, TikTok will know which
              audience to serve your content to.
              <br /> <br />
              And from a story I&apos;ve heard from a TikToker with 10M
              followers, it&apos;s really hard to switch niches later on once
              TikTok has determined where your content fits.
              <br /> <br />
              One reason for that might be because some of your first views are
              from your followers.
              <br /> <br />
              And if they followed you for your original niche, they might not
              finish watching your new content that TikTok serves them.
              <br /> <br />
              That, in turn, hurts your chances of going viral.
            </Text>

            <Text sx={classes.h2}>
              2. Research the &apos;For You&apos; Spotlight
            </Text>
            <Text sx={classes.bodyText}>
              Begin your research journey on the &apos;For You&apos; page,
              TikTok&apos;s showcase of personalized content recommendations.
              <br />
              <br />
              This space is reflective of what&apos;s currently captivating the
              TikTok community.
              <br />
              <br />
              Take the example of the #FlipTheSwitch challenge, which exploded
              in popularity due to its humor and easy-to-replicate format.
              <br />
              <br />
              It was a simple concept: two people switch places—and often
              outfits—when the lights go off during a snippet of the song
              &quot;Nonstop&quot; by Drake.
            </Text>
            <CardMedia
              component="img"
              image={img3}
              alt=""
              sx={classes.contentImage}
            />
            <Text sx={classes.bodyText}>
              Challenges like these are gold mines for engagement and can serve
              as a template for your content.
              <br /> <br />
              Once you find the ones you like, create a list of links to refer
              to later.
              <br /> <br />
              Alternatively, you can click the bookmark icon on the video to
              save it:
            </Text>
            <CardMedia
              component="img"
              image={img1}
              alt=""
              sx={{ ...classes.contentImage, width: "240px" }}
            />

            <Text sx={classes.h2}>3. Save Trending Sounds</Text>
            <Text sx={classes.bodyText}>
              TikTok is as much an auditory experience as it is visual.
              <br />
              <br />
              The meteoric rise of the song &quot;Savage Love&quot; by Jason
              Derulo in TikTok videos exemplifies how a catchy tune can become
              the backdrop for millions of videos.
              <br />
              <br />
              Whether it&apos;s a dance, a transformation video, or a comedic
              skit, trending sounds can carry your content far and wide.
              <br /> <br />
              Identifying such tracks early can place you ahead of the curve.
              <br /> <br />I give some tricks on finding trending sounds in the{" "}
              <a
                href="/tiktok-add-trending-sounds"
                target="_blank"
                rel="noreferrer"
              >
                Add Trending Sounds
              </a>{" "}
              section.
            </Text>

            <Text sx={classes.h2}>
              4. Create a List of Hashtags People in Your Niche Use
            </Text>
            <Text sx={classes.bodyText}>
              Hashtags are your roadmap to visibility on TikTok.
              <br />
              <br />
              They help TikTok&apos;s algorithm categorize your content and
              enhance your video&apos;s discoverability.
              <br />
              <br />
              When #LearnOnTikTok trended, it wasn&apos;t just about educational
              content; it symbolized TikTok&apos;s evolution into a platform for
              knowledge sharing, covering everything from science experiments to
              history lessons.
              <br />
              <br />
              <CardMedia
                component="img"
                image={img6}
                alt=""
                sx={classes.contentImage}
              />
              Using the right hashtags is like choosing the fast lane to viral
              visibility.
              <br /> <br />I give some tricks on which hashtags and how many
              hashtags you should use in the{" "}
              <a
                href="/tiktok-incorporate-hashtags"
                target="_blank"
                rel="noreferrer"
              >
                Incorporate Hashtags
              </a>{" "}
              section.
            </Text>

            <Text sx={classes.h2}>5. Create with a Personal Twist</Text>
            <Text sx={classes.bodyText}>
              Adapting trends to reflect your unique style is crucial.
              <br />
              <br />
              When the #BoredInTheHouse trend took off, creators didn&apos;t
              just lip-sync to the soundtrack; they showcased their{" "}
              <a
                href="https://www.tiktok.com/@nianaguerrero/video/6810965203530812677?q=BoredInTheHouse&t=1708667306895"
                target="_blank"
                rel="noreferrer"
              >
                quirky at-home activities
              </a>
              , bringing personal flair to a common theme.
              <br />
              <br />
              <CardMedia
                component="img"
                image={img4}
                alt=""
                sx={classes.contentImage}
              />
              It’s about making the trend your own and standing out in a sea of
              similar content.
            </Text>

            <Text sx={classes.h2}>6. Forecasting Virality</Text>
            <Text sx={classes.bodyText}>
              Observing emerging trends can give you a head start.
              <br />
              <br />
              Notice subtle shifts in content and ask yourself what might come
              next.
              <br />
              <br />
              For instance, with the rise of at-home fitness during lockdowns,
              TikTok saw an influx of #HomeWorkout videos.
              <br />
              <br />
              <CardMedia
                component="img"
                image={img5}
                alt=""
                sx={classes.contentImage}
              />
              Creators who capitalized on this early enjoyed a significant boost
              in visibility.
            </Text>

            <Text sx={classes.h2}>Bonus Insights: Beyond the Algorithm</Text>
            <Text sx={classes.bodyText}>
              Understanding TikTok&apos;s algorithm is beneficial, but
              don&apos;t overlook the power of community and interaction.
              <br />
              <br />
              Engage with followers by hopping onto Live sessions, asking for
              content suggestions, and replying to comments with personalized
              videos.
              <br />
              <br />
              This can strengthen your relationship with your audience and
              increase the likelihood of your content being favored by
              TikTok&apos;s algorithm.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
