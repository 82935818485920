import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/heart-shake-hands.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function BrandPartnerships() {
  const backgroundColor = "#8BE8C6";
  const subtitle = "Brand Partnerships";
  const title =
    "Maximizing TikTok Success: Securing Brand Partnerships in 2024";

  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: backgroundColor,
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>{subtitle}</Text>
                <Text variant="h1" sx={classes.title}>
                  {title}
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              For TikTok creators, turning viral success into brand partnerships
              represents a significant opportunity to monetize and expand their
              influence.
              <br /> <br /> Here&apos;s how to attract and integrate brand deals
              authentically in 2024:
            </Text>

            <Text sx={classes.h2}>1. Showcase Your Unique Value</Text>
            <Text sx={classes.bodyText}>
              Brands are drawn to creators who offer something unique, whether
              it&apos;s a distinctive style, niche content, or a highly engaged
              audience.
              <br /> <br /> Clearly define what sets you apart and highlight
              these aspects in your TikTok bio and content.
            </Text>

            <Text sx={classes.h2}>2. Maintain Consistency</Text>
            <Text sx={classes.bodyText}>
              Consistency in posting and content quality builds a loyal
              following, making you more attractive to brands.
              <br /> <br /> Keep producing content that aligns with your niche
              and voice, demonstrating your reliability as a partner.
            </Text>

            <Text sx={classes.h2}>3. Understand Your Audience</Text>
            <Text sx={classes.bodyText}>
              Deep knowledge of your audience is invaluable in brand
              partnerships.
              <br /> <br /> Use TikTok Analytics to gather insights on your
              followers&apos; demographics, interests, and engagement patterns.
              <br /> <br />
              Brands will be interested in partnerships that offer direct access
              to their target demographic.
            </Text>

            <Text sx={classes.h2}>4. Reach Out to Brands</Text>
            <Text sx={classes.bodyText}>
              Don&apos;t wait for brands to come to you.
              <br /> <br /> Proactively reach out to brands that align with your
              content and audience.
              <br /> <br /> Craft a compelling pitch that includes your TikTok
              stats, audience insights, and ideas for potential collaborations.
            </Text>

            <Text sx={classes.h2}>5. Leverage Agencies and Platforms</Text>
            <Text sx={classes.bodyText}>
              Consider joining influencer marketing platforms or agencies that
              specialize in connecting TikTok creators with brands.
              <br /> <br /> These platforms can help you find partnership
              opportunities that match your niche and content style.
            </Text>

            <Text sx={classes.h2}>6. Highlight Successful Collaborations</Text>
            <Text sx={classes.bodyText}>
              If you&apos;ve had successful brand partnerships in the past,
              showcase them on your profile or in a portfolio.
              <br /> <br /> This not only builds your credibility but also shows
              potential partners the quality and impact of your collaborative
              work.
            </Text>

            <Text sx={classes.h2}>7. Prioritize Authentic Integration</Text>
            <Text sx={classes.bodyText}>
              When integrating sponsored content, prioritize authenticity.
              <br /> <br /> Your audience values your genuine opinion, so work
              with brands that you truly like and would naturally fit into your
              content.
              <br /> <br /> Authenticity fosters trust and maintains your
              audience&apos;s engagement.
            </Text>

            <Text sx={classes.h2}>8. Negotiate Fairly</Text>
            <Text sx={classes.bodyText}>
              Understand your worth and be prepared to negotiate to ensure fair
              compensation for your work.
              <br /> <br /> Consider factors like your reach, engagement rate,
              and the effort required to create the content when discussing
              terms with brands.
            </Text>

            <Text sx={classes.h2}>9. Disclose Partnerships</Text>
            <Text sx={classes.bodyText}>
              Transparency is key.
              <br /> <br /> Always disclose brand partnerships to your audience
              in accordance with TikTok&apos;s guidelines and local regulations.
              <br /> <br /> This maintains trust with your followers and ensures
              compliance.
            </Text>

            <Text sx={classes.h2}>10. Evaluate and Learn</Text>
            <Text sx={classes.bodyText}>
              After a partnership, evaluate the campaign&apos;s performance and
              gather feedback from the brand and your audience.
              <br /> <br /> This learning will guide you in refining your
              approach to future collaborations.
              <br /> <br />
              In 2024, leveraging your TikTok success to secure brand
              partnerships offers a pathway to monetization and growth.
              <br /> <br /> By understanding your unique value, maintaining
              authenticity, and strategically selecting brand collaborations,
              you can create sponsored content that resonates with your audience
              and strengthens your personal brand.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
