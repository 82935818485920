import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../src/components/templates/Navbar/Navbar";
import Footer from "../src/components/templates/Footer/Footer";
import Home from "./webpages/Home";

// Import checklist item pages
// import Blog from "./webpages/blog/Blog";
import TrendResearch from "./webpages/checklistItemPages/TrendResearch";
import AudienceUnderstanding from "./webpages/checklistItemPages/AudienceUnderstanding";
import ContentPlanning from "./webpages/checklistItemPages/ContentPlanning";
import EngagingStart from "./webpages/checklistItemPages/EngagingStart";
import HighQualityVisuals from "./webpages/checklistItemPages/HighQualityVisuals";
import ClearAudio from "./webpages/checklistItemPages/ClearAudio";
import OptimalLength from "./webpages/checklistItemPages/OptimalLength";
import EditForImpact from "./webpages/checklistItemPages/EditForImpact";
import AddTrendingSounds from "./webpages/checklistItemPages/AddTrendingSounds";
import IncorporateHashtags from "./webpages/checklistItemPages/IncorporateHashtags";
import StrategicPosting from "./webpages/checklistItemPages/StrategicPosting";
import EngageWithYourAudience from "./webpages/checklistItemPages/EngageWithYourAudience";
import CrossPromotion from "./webpages/checklistItemPages/CrossPromotion";
import Collaborate from "./webpages/checklistItemPages/Collaborate";
import ReviewPerformance from "./webpages/checklistItemPages/ReviewPerformance";
import Iterate from "./webpages/checklistItemPages/Iterate";
import TikToksCreatorFund from "./webpages/checklistItemPages/TikToksCreatorFund";
import BrandPartnerships from "./webpages/checklistItemPages/BrandPartnerships";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <title>Viral Genius AI – Go Viral On TikTok</title>
                <meta
                  name="description"
                  content="Convert your MP4 files to GIF files fast and for free."
                />
              </Helmet>
              <Navbar />
              <Home />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-trend-research"
          element={
            <>
              <Helmet>
                <title>
                  Navigating TikTok Trends: A Beginner&apos;s Guide to Viral
                  Success in 2024
                </title>
                <meta
                  name="description"
                  content="Unlock the secrets of TikTok's viral trends with our comprehensive beginner's guide. Learn how to navigate the 'For You' and 'Discover' pages, utilize popular sounds and hashtags, and craft authentic content that resonates in 2024. Whether you're aiming for the 'For You' page or participating in the latest challenges, our strategies will help you capture the essence of TikTok's dynamic platform and achieve viral success. Start your journey to becoming a TikTok trendsetter today!"
                />
              </Helmet>
              <Navbar />
              <TrendResearch />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-audience-understanding"
          element={
            <>
              <Helmet>
                <title>Unlocking Audience Insights for TikTok Mastery</title>
                <meta
                  name="description"
                  content="Discover the key to TikTok success by diving deep into audience insights. Learn how to analyze and understand your followers to create content that resonates, boosts engagement, and drives growth."
                />
              </Helmet>
              <Navbar />
              <AudienceUnderstanding />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-content-planning"
          element={
            <>
              <Helmet>
                <title>
                  Crafting Trend-Inspired TikTok Content with a Personal Spin
                </title>
                <meta
                  name="description"
                  content="Unlock the secrets to viral TikTok content with our comprehensive guide on trend-inspired planning and personalization. Tailor your strategy to captivate your audience with every post."
                />
              </Helmet>
              <Navbar />
              <ContentPlanning />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-engaging-start"
          element={
            <>
              <Helmet>
                <title>Capturing Attention on TikTok: The 3-Second Hook</title>
                <meta
                  name="description"
                  content="Master the art of the 3-second hook with our strategies for capturing attention on TikTok. Elevate your content's impact from the very start to keep viewers watching."
                />
              </Helmet>
              <Navbar />
              <EngagingStart />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-high-quality-visuals"
          element={
            <>
              <Helmet>
                <title>
                  High-Quality Visuals: Elevating Your TikTok Presence
                </title>
                <meta
                  name="description"
                  content="Elevate your TikTok presence with stunning visuals. Our guide shows you how to enhance your videos with high-quality imagery, setting your content apart from the crowd."
                />
              </Helmet>
              <Navbar />
              <HighQualityVisuals />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-clear-audio"
          element={
            <>
              <Helmet>
                <title>
                  High-Quality Audio: The Key to Captivating TikTok Content
                </title>
                <meta
                  name="description"
                  content="Learn the importance of crystal-clear audio in creating captivating TikTok content. Discover techniques to improve sound quality that engages and retains your audience."
                />
              </Helmet>
              <Navbar />
              <ClearAudio />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-optimal-length"
          element={
            <>
              <Helmet>
                <title>
                  Optimal Length for TikTok Videos: Keeping Viewers Engaged in
                  2024
                </title>
                <meta
                  name="description"
                  content="Find the sweet spot for your TikTok videos with our guide on optimal length. Keep your audience engaged in 2024 with content that's just right, maximizing views and interaction."
                />
              </Helmet>
              <Navbar />
              <OptimalLength />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-edit-for-impact"
          element={
            <>
              <Helmet>
                <title>
                  Editing for Impact: Elevating Your TikTok Videos in 2024
                </title>
                <meta
                  name="description"
                  content="Transform your TikTok videos with editing techniques that make an impact. Our 2024 guide provides the tips and tricks you need to elevate your content and engage your audience."
                />
              </Helmet>
              <Navbar />
              <EditForImpact />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-add-trending-sounds"
          element={
            <>
              <Helmet>
                <title>
                  Leveraging Trending Sounds on TikTok: A 2024 Guide to Boosting
                  Discoverability
                </title>
                <meta
                  name="description"
                  content="Boost your TikTok discoverability by incorporating trending sounds. Our 2024 guide offers strategies for selecting and using sounds that enhance your content's reach and appeal."
                />
              </Helmet>
              <Navbar />
              <AddTrendingSounds />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-incorporate-hashtags"
          element={
            <>
              <Helmet>
                <title>
                  Mastering Hashtags on TikTok: Expanding Your Reach in 2024
                </title>
                <meta
                  name="description"
                  content="Expand your TikTok reach with the strategic use of hashtags. Learn how to master hashtag trends in 2024 to maximize visibility and attract a larger audience."
                />
              </Helmet>
              <Navbar />
              <IncorporateHashtags />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-strategic-posting"
          element={
            <>
              <Helmet>
                <title>
                  Strategic Posting on TikTok: Navigating Peak Times in 2024
                </title>
                <meta
                  name="description"
                  content="Optimize your TikTok strategy with insights on the best times to post in 2024. Engage with your audience when it matters most to increase visibility and engagement."
                />
              </Helmet>
              <Navbar />
              <StrategicPosting />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-engage-with-your-audience"
          element={
            <>
              <Helmet>
                <title>
                  Engaging With Your Audience on TikTok: A 2024 Strategy for
                  Enhanced Interaction
                </title>
                <meta
                  name="description"
                  content="Strengthen your TikTok community by engaging directly with your audience. Our 2024 strategy guide shows you how to foster interaction and build lasting relationships."
                />
              </Helmet>
              <Navbar />
              <EngageWithYourAudience />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-cross-promotion"
          element={
            <>
              <Helmet>
                <title>
                  Amplifying Your TikTok Reach: Cross-Promotion Strategies for
                  2024
                </title>
                <meta
                  name="description"
                  content="Amplify your TikTok reach through effective cross-promotion strategies in 2024. Learn how to leverage other platforms and collaborations to boost your audience and engagement."
                />
              </Helmet>
              <Navbar />
              <CrossPromotion />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-collaborate"
          element={
            <>
              <Helmet>
                <title>
                  Elevating Your TikTok Presence: The Power of Collaboration in
                  2024
                </title>
                <meta
                  name="description"
                  content="Unlock the power of collaboration to boost your TikTok presence in 2024. Discover how partnering with other creators can elevate your content and expand your reach."
                />
              </Helmet>
              <Navbar />
              <Collaborate />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-review-performance"
          element={
            <>
              <Helmet>
                <title>
                  Mastering TikTok Analytics: A Guide to Refining Your Content
                  Strategy in 2024
                </title>
                <meta
                  name="description"
                  content="Refine your TikTok strategy with analytics. Our guide to mastering TikTok's performance metrics in 2024 helps you understand what works and how to optimize for success."
                />
              </Helmet>
              <Navbar />
              <ReviewPerformance />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-iterate"
          element={
            <>
              <Helmet>
                <title>
                  Continuously Refine Your TikTok Content Strategy: A 2024 Guide
                </title>
                <meta
                  name="description"
                  content="Stay ahead on TikTok by continuously refining your content strategy. Our 2024 guide offers insights on adapting to trends, audience preferences, and performance feedback."
                />
              </Helmet>
              <Navbar />
              <Iterate />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-tiktoks-creator-fund"
          element={
            <>
              <Helmet>
                <title>
                  Monetizing Your TikTok Presence: Navigating TikTok’s Creator
                  Fund in 2024
                </title>
                <meta
                  name="description"
                  content="Navigate TikTok’s Creator Fund in 2024 to monetize your presence effectively. Learn the ins and outs of eligibility, application, and maximizing your earnings through our guide."
                />
              </Helmet>
              <Navbar />
              <TikToksCreatorFund />
              <Footer />
            </>
          }
        />

        <Route
          path="/tiktok-brand-partnerships"
          element={
            <>
              <Helmet>
                <title>
                  Maximizing TikTok Success: Securing Brand Partnerships in 2024
                </title>
                <meta
                  name="description"
                  content="Secure lucrative brand partnerships on TikTok in 2024 with our strategic guide. Learn how to attract and collaborate with brands to boost your profile and profitability."
                />
              </Helmet>
              <Navbar />
              <BrandPartnerships />
              <Footer />
            </>
          }
        />

        {/* <Route
          path="/tiktok-XXXXXXXXXXXXXXXXXXXX"
          element={
            <>
              <Helmet>
                <title>XXXXXXXXXXXXXXXXXXXX</title>
                <meta name="description" content="XXXXXXXXXXXXXXXXXXXX" />
              </Helmet>
              <Navbar />
              <XXXXXXXXXXXXXXXXXXXX />
              <Footer />
            </>
          }
        /> */}

        {/* <Route
          path="/mp4-to-gif-blog"
          element={
            <>
              <Helmet>
                <title>MP4 to GIF Blog</title>
                <meta
                  name="description"
                  content="Browse our blog to help you with your MP4 to GIF conversion needs."
                />
              </Helmet>
              <Navbar />
              <Blog />
              <Footer />
            </>
          }
        /> */}

        {/* <Route
          path="/how-to-convert-mp4-to-gif"
          element={
            <>
              <Helmet>
                <title>MP4 to GIF Blog</title>
                <meta
                  name="description"
                  content="A comprehensive guide to show you how to convert mp4 to gif files."
                />
              </Helmet>
              <Navbar />
              <HowToConvertMP4ToGIF />
              <Footer />
            </>
          }
        /> */}
      </Routes>
    </Router>
  );
}

export default App;
