import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/clock.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function OptimalLength() {
  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: "#FFD9C9",
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>Optimal Length</Text>
                <Text variant="h1" sx={classes.title}>
                  Optimal Length for TikTok Videos: Keeping Viewers Engaged in
                  2024
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              In the ever-evolving landscape of TikTok, maintaining viewer
              engagement is key to standing out.
              <br /> <br /> As we move into 2024, understanding the optimal
              video length on TikTok can make a significant difference in
              capturing and retaining your audience&apos;s attention.
              <br /> <br /> Here’s what you need to know:
            </Text>

            <Text sx={classes.h2}>Understanding TikTok&apos;s Format</Text>
            <Text sx={classes.bodyText}>
              TikTok videos can range from a few seconds to several minutes, but
              not all lengths are equally effective for engagement.
              <br /> <br /> The platform is known for its quick, digestible
              content that delivers value or entertainment in a short span of
              time.
            </Text>

            <Text sx={classes.h2}>The Sweet Spot: 15 to 60 Seconds</Text>
            <Text sx={classes.bodyText}>
              For most content creators, keeping videos between 15 to 60 seconds
              strikes the perfect balance.
              <br /> <br /> This range is long enough to convey a message or
              entertain but short enough to keep the viewer&apos;s attention
              from waning.
              <br /> <br /> Shorter videos (15-30 seconds) are ideal for simple,
              straightforward content, while the 30-60 second range can
              accommodate slightly more complex narratives or demonstrations.
            </Text>

            <Text sx={classes.h2}>Content Type Matters</Text>
            <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Quick Tips or Jokes: Aim for 15-30 seconds. Get to the
                  punchline or key takeaway quickly to maximize impact.
                </li>
                <li>
                  Tutorials and Demonstrations: If you&apos;re showing a
                  process, using the full minute allows you to provide enough
                  detail without rushing, making your content both engaging and
                  valuable.
                </li>
                <li>
                  Storytelling: For mini-vlogs or storytelling, 30-60 seconds
                  gives you enough time to build a narrative that&apos;s
                  engaging and emotionally resonant.
                </li>
              </ul>
            </Text>

            <Text sx={classes.bodyText}>
              TikTok&apos;s algorithm favors content that keeps users on the
              platform longer.
              <br /> <br /> Videos that engage viewers from start to finish,
              even if they&apos;re on the shorter side, tend to perform better
              in terms of visibility and engagement.
              <br /> <br /> Analyze your TikTok Insights to see what lengths
              work best for your audience and adjust your content strategy
              accordingly.
            </Text>

            <Text sx={classes.h2}>Keeping Up with Trends</Text>
            <Text sx={classes.bodyText}>
              TikTok trends can influence optimal video length.
              <br /> <br /> Stay updated with the platform&apos;s trends and
              challenges, as participating in these can sometimes require
              adapting your video length to fit the format that&apos;s currently
              popular.
              <br /> <br /> As TikTok continues to dominate the social media
              landscape in 2024, adapting your content to the optimal length
              based on your specific audience and content type is crucial.
              <br /> <br />
              Experiment within the 15 to 60-second range to find what resonates
              most with your viewers and drives engagement.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
