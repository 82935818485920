import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/pie-chart.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function ReviewPerformance() {
  const backgroundColor = "#4AADCB";
  const subtitle = "Review Performance";
  const title =
    "Mastering TikTok Analytics: A Guide to Refining Your Content Strategy in 2024";

  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: backgroundColor,
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>{subtitle}</Text>
                <Text variant="h1" sx={classes.title}>
                  {title}
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              In the fast-paced world of TikTok, understanding what resonates
              with your audience is crucial for sustained success.
              <br /> <br /> TikTok Analytics offers a goldmine of data to help
              creators review their video performance and adjust their
              strategies accordingly.
              <br /> <br /> Here’s how to make the most out of this tool in
              2024:
            </Text>

            <Text sx={classes.h2}>1. Access Your Analytics</Text>
            <Text sx={classes.bodyText}>
              First things first, ensure you have a TikTok Pro Account to access
              analytics.
              <br /> <br /> This feature offers insights into your
              account&apos;s overall performance, including detailed statistics
              on your followers, video views, and profile engagement.
            </Text>

            <Text sx={classes.h2}>2. Analyze Video Performance</Text>
            <Text sx={classes.bodyText}>
              Dive into the &apos;Content&apos; tab to see how individual videos
              perform.
              <br /> <br /> Pay attention to metrics like views, likes, shares,
              comments, and average watch time.
              <br /> <br /> High engagement and watch time indicate content that
              your audience enjoys.
            </Text>

            <Text sx={classes.h2}>3. Understand Your Audience</Text>
            <Text sx={classes.bodyText}>
              The &apos;Followers&apos; tab provides valuable information about
              your audience demographics, including gender breakdown, top
              territories, and times when your followers are most active.
              <br /> <br />
              Tailoring your content to match your audience&apos;s preferences
              can lead to higher engagement rates.
            </Text>

            <Text sx={classes.h2}>4. Track Profile Views and Engagement</Text>
            <Text sx={classes.bodyText}>
              Monitoring how many users visit your profile and engage with your
              content can offer insights into the effectiveness of your TikTok
              strategy.
              <br /> <br /> If you notice a spike in profile views after posting
              a particular type of content, consider producing more of that
              content.
            </Text>

            <Text sx={classes.h2}>5. Experiment and Adjust</Text>
            <Text sx={classes.bodyText}>
              Use analytics to experiment with different types of content,
              posting times, and hashtags.
              <br /> <br /> Analyze the performance of these experiments to
              identify what works best for your audience.
            </Text>

            <Text sx={classes.h2}>6. Monitor Trends</Text>
            <Text sx={classes.bodyText}>
              Keep an eye on trending sounds, hashtags, and challenges within
              the app.
              <br /> <br /> Use the &apos;Trending&apos; tab in TikTok Analytics
              to discover what&apos;s popular and consider incorporating these
              trends into your content strategy.
            </Text>

            <Text sx={classes.h2}>7. Set Goals and Monitor Progress</Text>
            <Text sx={classes.bodyText}>
              Set specific, measurable goals for your TikTok account (e.g.,
              increase followers by 10% in a month) and use analytics to track
              your progress.
              <br /> <br /> Adjust your strategy as needed to meet these goals.
            </Text>

            <Text sx={classes.h2}>8. Engage With Your Analytics</Text>
            <Text sx={classes.bodyText}>
              Regularly reviewing your TikTok Analytics is not a one-time task
              but an ongoing process.
              <br /> <br /> Dedicate time each week to analyze your data,
              identify trends, and adjust your content strategy accordingly.
              <br /> <br /> In 2024, leveraging TikTok Analytics is essential
              for creators looking to grow their audience and enhance their
              content strategy.
              <br /> <br /> By understanding your video performance, audience
              demographics, and engagement patterns, you can make informed
              decisions that propel your TikTok presence to new heights.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
