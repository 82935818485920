import { createTheme } from "@mui/material/styles";

export let customTheme = createTheme({
  breakpoints: {
    values: {
      xl: 2560,
      lg: 1440,
      md: 1024,
      sm: 768,
      xs: 425,
      zero: 0,
    },
  },
});
