import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/hash.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function IncorporateHashtags() {
  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: "#FABDC0",
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>Incorporate Hashtags</Text>
                <Text variant="h1" sx={classes.title}>
                  Mastering Hashtags on TikTok: Expanding Your Reach in 2024
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              On TikTok, hashtags aren&apos;t just a way to categorize
              content—they&apos;re a powerful tool for amplifying reach and
              connecting with your target audience.
              <br /> <br /> As we navigate through 2024, understanding how to
              strategically use hashtags can significantly enhance your TikTok
              strategy.
              <br /> <br /> Here&apos;s how to make hashtags work for you:
            </Text>

            <Text sx={classes.h2}>1. Blend Trending and Niche Hashtags</Text>
            <Text sx={classes.bodyText}>
              Combining trending hashtags with niche-specific ones can help you
              tap into larger conversations while still reaching those in your
              specific community.
              <br /> <br /> Trending hashtags get you noticed on broader
              platforms, and niche hashtags connect you with your target
              audience.
            </Text>

            <Text sx={classes.h2}>
              2. Research Hashtags Before You Use Them
            </Text>
            <Text sx={classes.bodyText}>
              Spend time on the &quot;Discover&quot; page and look at what
              hashtags influencers and competitors in your niche are using.
              <br /> <br /> This research can reveal insights into what&apos;s
              currently capturing the TikTok community&apos;s attention and how
              you can align your content with these trends.
            </Text>

            <Text sx={classes.h2}>3. Use Hashtags to Tell Your Story</Text>
            <Text sx={classes.bodyText}>
              Hashtags can also serve as a narrative tool, adding context or
              emotion to your videos.
              <br /> <br /> Clever or thematic hashtags can make your content
              more relatable and shareable, further increasing its reach.
            </Text>

            <Text sx={classes.h2}>4. Keep an Eye on Hashtag Challenges</Text>
            <Text sx={classes.bodyText}>
              Participating in or creating your own hashtag challenge can
              dramatically boost engagement.
              <br /> <br /> Challenges often go viral, driving massive traffic
              to your profile.
              <br /> <br /> Use relevant hashtags to ensure your challenge is
              discoverable to the right audience.
            </Text>

            <Text sx={classes.h2}>
              5. Don&apos;t Overload Your Caption with Hashtags
            </Text>
            <Text sx={classes.bodyText}>
              While it&apos;s tempting to use as many hashtags as possible,
              TikTok&apos;s algorithm favors relevance and engagement over
              quantity.
              <br /> <br /> A good rule of thumb is to use between 3 to 5
              targeted hashtags per post to ensure your content is easily
              discoverable without overwhelming your audience.
            </Text>

            <Text sx={classes.h2}>6. Track Hashtag Performance</Text>
            <Text sx={classes.bodyText}>
              Utilize TikTok Analytics to track the performance of the hashtags
              you use.
              <br /> <br /> Understanding which hashtags drive the most
              engagement and reach can help you refine your hashtag strategy
              over time, focusing on what works best for your content.
              <br /> <br /> In 2024, hashtags remain a crucial element of TikTok
              content strategy, serving as a bridge between your content and
              your intended audience.
              <br /> <br /> By strategically incorporating a mix of trending and
              niche hashtags, researching their performance, and using them
              creatively, you can significantly enhance your TikTok presence,
              making your content more discoverable, engaging, and shareable.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
