import React from "react";
import { Box } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

// import components
import Text from "../../components/atoms/Text/Text";

// import style
import { classes } from "./CheckListPageStyle";

// import icons
import bannerIcon from "../../assets/icons/alert-circle-1.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export default function EditForImpact() {
  return (
    <main>
      <article>
        <Box sx={classes.container}>
          <header>
            <Box sx={classes.innerHeader}>
              <Link
                to="/"
                style={{ textDecoration: "none", alignSelf: "flex-start" }}
              >
                <Box sx={classes.backBtn}>
                  <CardMedia
                    component="img"
                    image={arrowLeft}
                    alt=""
                    sx={classes.backIcon}
                  />
                  <Text sx={classes.backTxt}>Back to checklist</Text>
                </Box>
              </Link>

              <Box
                sx={{
                  ...classes.bannerIconContainer,
                  backgroundColor: "#E48187",
                }}
              >
                <CardMedia
                  component="img"
                  image={bannerIcon}
                  alt=""
                  sx={classes.bannerIcon}
                />
              </Box>

              <Box sx={classes.titleAndMetaSection}>
                <Text sx={classes.subtitle}>Edit for Impact</Text>
                <Text variant="h1" sx={classes.title}>
                  Editing for Impact: Elevating Your TikTok Videos in 2024
                </Text>
              </Box>
            </Box>
          </header>

          <Box sx={classes.contentContainer}>
            <Text sx={classes.bodyText}>
              In the fast-paced world of TikTok, editing is not just about
              cutting clips; it&apos;s an art form that can significantly
              elevate the impact of your content.
              <br /> <br /> As we head into 2024, the competition for
              viewers&apos; attention is fiercer than ever, making it crucial to
              use editing techniques that not only enhance engagement but also
              ensure your videos stand out. Here’s how to edit your TikTok
              videos for maximum impact:
            </Text>

            <Text sx={classes.h2}>1. Master the Art of Transitions</Text>
            <Text sx={classes.bodyText}>
              Transitions are the secret sauce that can make your videos flow
              seamlessly from one scene to the next.
              <br /> <br /> Whether it&apos;s a simple cut, a swipe, a zoom, or
              a custom animation, transitions should be used purposefully to
              maintain momentum and keep viewers engaged.
              <br /> <br />
              Experiment with TikTok’s built-in transition effects or use
              editing software to create your own unique transitions that match
              the rhythm of your content.
            </Text>

            <Text sx={classes.h2}>2. Leverage Effects and Filters</Text>
            <Text sx={classes.bodyText}>
              TikTok offers a plethora of effects and filters that can transform
              the look and feel of your videos.
              <br /> <br /> Use these tools creatively to add flair or to convey
              a particular mood or theme.
              <br /> <br /> From glitch effects that add edginess to dreamy
              filters that soften your visuals, the right effect can make your
              video more engaging and memorable.
            </Text>

            <Text sx={classes.h2}>
              3. Incorporate Text Overlays for Clarity
            </Text>
            <Text sx={classes.bodyText}>
              Text overlays are a fantastic way to enhance your message or add
              context where audio might not be enough.
              <br /> <br /> Use bold, readable fonts and colors that contrast
              with your background to ensure your text is easy to read.
              <br /> <br /> Keep your messages concise and time your text to
              appear precisely when you want your audience to read them.
            </Text>

            <Text sx={classes.h2}>4. Sync Your Edits to Music</Text>
            <Text sx={classes.bodyText}>
              Music is a core component of the TikTok experience.
              <br /> <br /> Syncing your visual edits to the beat of your chosen
              soundtrack can create a highly engaging and dynamic video.
              <br /> <br /> Pay attention to beats, rhythms, and musical cues,
              and align your cuts and transitions to these moments for a
              polished, professional feel.
            </Text>

            <Text sx={classes.h2}>
              5. Use Speed Adjustments for Dramatic Effect
            </Text>
            <Text sx={classes.bodyText}>
              Playing with video speed can add drama or comedy to your content.
              <br /> <br />
              Use slow motion to highlight a moment or speed up your footage for
              comedic effect or to fast-forward through less critical parts of
              your video.
              <br /> <br /> The TikTok editor allows you to adjust the speed of
              your clips easily, giving you the flexibility to experiment with
              tempo.
            </Text>

            <Text sx={classes.h2}>6. Keep It Concise and Focused</Text>
            <Text sx={classes.bodyText}>
              With TikTok, shorter often means better.
              <br /> <br /> Edit your content ruthlessly to keep only the most
              engaging and relevant parts.
              <br /> <br /> Aim for a video length that maintains your
              narrative&apos;s essence without dragging on.
              <br /> <br /> This will ensure viewers stay with you till the end.
            </Text>

            <Text sx={classes.h2}>7. Consistency in Visual Style</Text>
            <Text sx={classes.bodyText}>
              Maintaining a consistent visual style across your videos can help
              in building a recognizable brand on TikTok.
              <br /> <br /> Whether it&apos;s a color scheme, a filter, or a
              shooting style, consistency makes your content instantly
              recognizable to your followers, fostering a stronger connection.
              <br /> <br /> Editing for impact on TikTok requires a blend of
              creativity, timing, and technical skill.
              <br /> <br /> By mastering transitions, leveraging effects,
              syncing with music, and keeping your content focused, you can
              create TikTok videos that not only captivate your audience but
              also drive engagement and shares.
              <br /> <br />
              Remember, the goal is to make each second count, ensuring that
              from start to finish, your content is impossible to ignore.
            </Text>

            {/* <Text sx={classes.bodyText}>
              <ul>
                <li>
                  Use TikTok&apos;s library to find trending audio for your
                  videos.
                </li>
                <li>
                  Incorporate relevant hashtags to boost your post&apos;s reach.
                </li>
              </ul>
            </Text> */}

            {/* <a
                style={classes.link}
                href="https://www.lifewire.com/mp4-file-2622024#:~:text=MP4%20files%20are%20usually%20seen,Lifewire%20%2F%20Tim%20Liedtke"
              >
                MP4 perfect for streaming over the internet
              </a> */}
          </Box>
        </Box>
      </article>
    </main>
  );
}
