import { customTheme } from "../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  outerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
  },
  title: {
    marginTop: "40px",
    fontSize: "48px",
    display: "block",
    textAlign: "center",
    [customTheme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
  subtitle: {
    marginTop: "24px",
    textAlign: "center",
    fontSize: "18px",
  },

  // Main content
  mainContent: {
    marginTop: "64px",
    width: "1440px",
    [customTheme.breakpoints.down(1440)]: {
      width: "inherit",
    },
  },
  itemGrid: {
    display: "grid",
    // gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gridTemplateColumns: "repeat(3, 1fr)",
    [customTheme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [customTheme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
};
