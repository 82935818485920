import React from "react";
import { Box } from "@mui/material";

// import components
import Text from "../../atoms/Text/Text";

// import style
import { classes } from "./FooterStyle";

export default function Footer() {
  return (
    <Box sx={classes.container}>
      <footer>
        <Text sx={classes.footerName}>Viral Genius AI</Text>
      </footer>
    </Box>
  );
}
